<template>
    <el-container class="shopList ">
        <div v-if="!showCamScreen">
            <el-header class="box add">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item>企业物联开关配置列表</el-breadcrumb-item>
                    <el-breadcrumb-item>物联开关列表</el-breadcrumb-item>
                </el-breadcrumb>
            </el-header>
            <el-form :inline="true" :model="camListQuery" class="demo-form-inline">
                <el-col :span="4" class="mr10">
                    <el-form-item size="medium">
                        <el-input
                                placeholder="物联开关名称"
                                v-model="camListQuery.socket_name"
                                class="input-with-select"
                        >
                        </el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6" class="tl mr10">
                    <el-form-item size="medium">
                        <el-button
                                type="primary"
                                @click="search"
                                class="general_bgc general_border cfff"
                        >查询</el-button
                        >
                        <el-button type="primary" size="medium" @click="Add" v-if="user_tag === 1"
                        >新增物联开关</el-button>
                    </el-form-item>
                </el-col>
            </el-form>
            <div >
                <el-table
                        border
                        :data="tableData"
                        style="width: 100%">
                    <el-table-column
                            prop="socket_number"
                            label="物联开关编号"
                            width="80">
                    </el-table-column>
                    <el-table-column
                            prop="socket_id"
                            label="物联开关id"
                            width="180">
                    </el-table-column>

                    <el-table-column
                            prop="socket_name"
                            label="物联开关名称"
                            width="180">
                    </el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button v-if="user_tag === 1" type="text" class="disib blue" size="mini" @click="handleManage(scope.row)">权限管理</el-button>
                            <el-button v-if="user_tag === 1" type="text" class="disib blue" size="mini" @click="handleNotice(scope.row)">上下线通知</el-button>
                            <el-button type="text" class="disib blue" size="mini" @click="handleUpdate(scope.row)">修改</el-button>
                            <el-button type="text" class="disib red" size="mini" @click="handleDelete(scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="i-page fr disib mt20">
                    <el-pagination
                            background
                            :page-size="9"
                            layout="total,prev, pager, next"
                            :total="total"
                            :current-page="currentPage"
                            @current-change="currentChange"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
        <el-dialog :title="handleName+title" :visible.sync="dialogAddgsVisible"   @close="closeDialogAddgsVisible" width="30%">
            <el-form :rules="rules" ref="form3" :model="form3" label-position="right" label-width="200px">
                <el-form-item label="物联开关id:" prop="socket_id" >
                    <el-input
                            placeholder="物联开关id"
                            v-model="form3.socket_id"
                            clearable
                            style="width: 60%;float: left"
                    >
                    </el-input>
                </el-form-item>
                <el-form-item   label="物联开关编号:" prop="socket_number" >
                    <el-input
                            placeholder="物联开关编号"
                            v-model="form3.socket_number"
                            clearable
                            style="width: 60%;float: left"
                    >
                    </el-input>
                </el-form-item>


                <el-form-item  label="物联开关名称:" prop="socket_name" >
                    <el-input
                            placeholder="物联开关名称"
                            v-model="form3.socket_name"
                            clearable
                            style="width: 60%;float: left"
                    >
                    </el-input>
                </el-form-item>
                    <el-button type="primary" size="medium" @click="submit"
                    >{{handleName}}</el-button
                    >
            </el-form>
        </el-dialog>

        <el-dialog :title="videoTitle" :visible.sync="videoShow"   @close="closeDialogAddgsVisible" >
            <iframe :src="videoUrl" frameborder="0"  width="100%" height="980px"
                    scrolling="auto"></iframe>
        </el-dialog>

        <el-dialog title="权限管理" :visible.sync="manageVisible" width="35%">
            <div>
                <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedUser" @change="handleCheckedCitiesChange" size="medium">
                    <el-checkbox style="width: 20%;margin: 20px" v-for="user in userList" :label="user.user_id" :key="user.user_id" :border="true"><div> {{user.user_name}}</div></el-checkbox>
                </el-checkbox-group>
            </div>
            <div>
                <el-button type="primary" round @click="handleSaveUser">保存</el-button>
            </div>
        </el-dialog>
        <el-dialog title="上下线通知" :visible.sync="noticeVisible" width="35%">
            <div>
                <el-checkbox :indeterminate="isNoticeIndeterminate" v-model="checkNoticeAll" @change="handleCheckAllNoticeChange">全选</el-checkbox>
                <div style="margin: 15px 0;"></div>
                <el-checkbox-group v-model="checkedNoticeUser" @change="handleCheckedCitiesNoticeChange" size="medium">
                    <el-checkbox style="width: 20%;margin: 20px" v-for="user in userNoticeList" :label="user.user_id" :key="user.user_id" :border="true"><div> {{user.user_name}}</div></el-checkbox>
                </el-checkbox-group>
            </div>
            <div>
                <el-button type="primary" round @click="handleSaveNoticeUser">保存</el-button>
            </div>
        </el-dialog>
    </el-container>
</template>
<script>
    import axios from '../../../util/api'
    import api from '../../../util/extra-api'
    export default {

        data () {
            return {
                camId: null,
                timeValue: "",
                imageSrc: "",
                picLoading: false,
                picsVisible: false,
                manageVisible:false,
                tableData:[],
                videoUri:"https://extlive.esunadv.com/?url=",
                videoTitle:"",
                form3:{
                    company_id:"",
                    socket_id: '',
                    socket_number:'',
                    socket_name: '',
                    type:0
                },
                videoUrl:"",
                handleName:"新增",
                title:"企业物联开关",
                currentPage: 1,
                allCurrentPage:1,
                total:0,
                allTotal:0,
                dialogAddgsVisible:false,
                companyId:[],
                videoShow:false,
                checkAll: false,
                checkedUser: [],
                isIndeterminate: true,
                rules: {
                    socket_id: [
                        { required: true, message: '请输入物联开关id', trigger: 'blur' },
                    ],
                    socket_number: [
                        { required: true, message: '请输入物联开关编号', trigger: 'blur' }
                    ],
                    socket_name: [
                        { required: true, message: '请输入物联开关名称', trigger: 'blur' }
                    ],
                },
                userList:[],
                selectedId: null,
                showCamScreen: false,
                camList: [],
                camListQuery: {
                    company_id: "",
                    pager_offset: 0,
                    pager_openset: 9,
                    socket_name: '',
                    type: 0
                },
                camTotal: 0,
                user_tag: 2,
                noticeVisible: false,
                checkedNoticeUser: [],
                userNoticeList: [],
                value1: null,
                id: null,
                checkNoticeAll: false,
                isNoticeIndeterminate: false

            }
        },
        mounted () {
            this.form3.company_id = localStorage.getItem("company_id");//获取当前企业
            this.camListQuery.company_id = localStorage.getItem("company_id");//获取当前企业
            this.getData();
            let user = JSON.parse(localStorage.getItem('user'))
            this.user_tag = user.user_tag
        },
        methods: {
            handleNotice(row){
                this.selectedId = row.socket_id
                this.id = row.id
                this.value1 = row.socket_number
                axios.get("/pc/company-user-iot/getHasIotUser",{ company_id: this.form3.company_id ,other_table_name : 'switch',other_table_id: row.id}).then((res) => {
                    if(res.code == 200){
                        this.userNoticeList = res.data
                        this.checkedNoticeUser = res.data.filter(m => m.tag).map(m => m.user_id)
                        this.noticeVisible = true
                    }
                });
            },
            handleCheckCam(){
              this.showCamScreen = true
            },
            handleSaveUser(){
                let data = {
                    user_id: this.checkedUser,
                    company_id: this.form3.company_id,
                    other_table_name: 'socket',
                    other_table_id: this.selectedId
                }
                axios.post("/pc/company-user-iot/save", data).then(res=>{
                    if(res.code == 200){
                        this.manageVisible = false
                    }
                })
            },
            handleSaveNoticeUser(){
                let data = {
                    toUserIds: this.checkedNoticeUser,
                    companyId: this.form3.company_id,
                    type: 'switch_message',
                    relatedId: this.selectedId,
                    value1: this.value1,
                    id: this.id
                }
                api.post("/v1/wx/warningNotice/pcAdd", data).then(res=>{
                    if(res.code == 200){
                        this.noticeVisible = false
                    }
                })
            },
            handleCheckAllChange(val) {
                this.checkedUser = val ? this.userList.map(m => m.user_id) : [];
                this.isIndeterminate = false;
            },
            handleCheckAllNoticeChange(val) {
                this.checkedNoticeUser = val ? this.userNoticeList.map(m => m.user_id) : [];
                this.isNoticeIndeterminate = false;
            },
            handleCheckedCitiesChange(value) {
                this.checkedUser = value
                //this.checkedUser = value.map(m => m.user_id)
                let checkedCount = value.length;
                this.checkAll = checkedCount === this.userList.length;
                this.isIndeterminate = checkedCount > 0 && checkedCount < this.userList.length;
            },
            handleCheckedCitiesNoticeChange(value) {
                this.checkedNoticeUser = value
                //this.checkedNoticeUser = value.map(m => m.user_id)
                let checkedCount = value.length;
                this.checkNoticeAll = checkedCount === this.userNoticeList.length;
                this.isNoticeIndeterminate = checkedCount > 0 && checkedCount < this.userNoticeList.length;
            },
            handleManage(row){
                this.selectedId = row.id
                axios.get("/pc/company-user-iot/getHasIotUser",{ company_id: this.form3.company_id ,other_table_name : 'socket',other_table_id: this.selectedId}).then((res) => {
                    if(res.code == 200){
                        this.userList = res.data
                        this.checkedUser = res.data.filter(m => m.tag).map(m => m.user_id)
                        this.manageVisible = true
                    }
                });
            },

            getData(){
                axios.get("/pc/company-socket/list",this.camListQuery).then(res=>{
                    res = res.data;
                    if (res){
                        this.tableData = res.socket_list;
                        this.total = res.pager_count;
                    }
                });
            },
            search(){
                this.getData();
            },
            handleUpdate(item){
                this.form3.id = item.id;
                this.form3.socket_id = item.socket_id;
                this.form3.socket_number = item.socket_number
                this.form3.socket_name = item.socket_name
                this.dialogAddgsVisible = true;
                this.handleName = '修改';
            },
            Add(){
                this.dialogAddgsVisible = true;
                this.form3.socket_id =""
                this.handleName = '添加'
            },

            handleDelete(row){
                axios.get("/pc/company-socket/delete/"+row.id).then(res=>{
                    this.dialogAddgsVisible =false;
                    this.getData(this.form3);
                })
            },
            submit(){
                this.$refs["form3"].validate((valid) => {
                    if (valid) {
                        if(!this.form3.id){
                            axios.post("/pc/company-socket/save",this.form3).then(res=>{
                                this.dialogAddgsVisible =false;
                                this.form3.socket_id ='';
                                this.form3.socket_name = '';
                                this.form3.socket_number = '';
                                this.getData(this.form3);
                            })
                        }else{
                            axios.post("/pc/company-socket/update",this.form3).then(res=>{
                                this.dialogAddgsVisible =false;
                                this.form3.socket_id ='';
                                this.form3.socket_name = '';
                                this.form3.socket_number = '';
                                this.getData(this.form3);
                            })
                        }
                    } else {
                        return false;
                    }
                });

            },
            closeDialogAddgsVisible(){
                this.dialogAddgsVisible = false;
            },
            currentChange(page) {
                this.currentPage = page;
                console.log('page',page)
                this.camListQuery.pager_offset = (page - 1) * this.camListQuery.pager_openset;
                this.getData();
            },
            allCurrentChange(page) {
                this.allCurrentPage = page;
                this.camListQuery.pager_offset = page - 1;
                this.getAllData();
            },
        }
    }
</script>
<style lang="scss" scoped>
    .shopList {
        overflow-x: hidden;
        .add {
            display: flex;
            top: 0px;
            left: 30px;
            justify-content: space-between;
            align-items: center;
        }
        .active {
            background: #2ec68a;
            border-color: #2ec68a;
            color: #ffffff;
        }
        .list_tab {
            padding: 10px;
            background-color: #f5f7fa;
            .list_tag {
                right: -10px;
                padding: 2px 6px;
                border-radius: 10px;
                top: -10px;
            }
        }
        /deep/ .el-input-group__append .el-button--primary .el-icon-search {
            color: #fff;
        }
        /deep/.el-table .aa .cell {
            display: flex;
            justify-content: center;
        }
    }
    $color: #2194E0;
    .button_hover {
        width: 80px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        text-decoration: none;
        color: #d3d7d4;
        border: 2px solid $color;
        font-size: 14px;
        display: inline-block;
        border-radius: 15px;
        transition: all 0.2s ease-in-out;
        position: relative;
        overflow: hidden;
        margin-right: 10px;
        &:before {
            content: "";
            background-color: rgba(255,255,255,0.5);
            height: 100%;
            width: 3em;
            display: block;
            position: absolute;
            top: 0;
            left: -4.5em;
            transform: skewX(-45deg) translateX(0);
            transition: none;
        }
        &:hover {
            cursor:pointer;
            background-color: $color;
            color: #fff;
            border-bottom: 4px solid darken($color, 10%);
            &:before {
                transform: skewX(-45deg) translateX(13.5em);
                transition: all 0.5s ease-in-out;
            }
        }
    }

    .activated{
        color: #1d953f;
    }
    .haccp-bg {
        transform-origin: 0px 0px 0px;
        background-image: url("../../../assets/image/mapbg.png");
        background-size: cover;
        background-position: 50% 0;
        background-color: rgb(0, 0, 0);
        min-width: auto;
        width: 100%;
        min-height: auto;
        height: 100%;
        overflow: auto;
        overflow-x: hidden;

    }

    .card-item {
        flex: 1;
        width: 31%;
        min-width: 31%;
        max-width: 31%;
        height: 400px;
        background: transparent;
        margin-bottom: 30px;
        border: 0.5px solid aqua;
        border-radius: 6px;
    }
    .card-item-title {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 10px 20px;
        background: transparent;
        text-align: center;
        font-size: 18px;
        color: #fff;
        border-bottom: 1px solid gainsboro;
    }

    .card-item-content {
        padding: 20px 10px;
        /*height: 20vw*/
    }
</style>
